import { useEffect } from 'react';
import { navigate } from 'gatsby';
import { isRegisteredForReset } from '../utils/registration';

const Registered = ({ registered = true, to = '/' }) => {
  useEffect(() => {    
    const r4r = isRegisteredForReset()
    if (r4r !== registered) {
      navigate(to);
    }
  });

  return null;
};

export default Registered;
