import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player/vimeo';

import device from '../utils/device';
import SEO from '../components/Seo';
import Layout from '../components/Layout';
import GlobalStyle from '../components/GlobalStyle';
import RegisterForm from '../components/RegisterForm';
import Registered from '../components/Registered';
import Logo from '../components/images/Logo';
import LandingImage from '../images/landing.jpg';
import LogoTa from '../images/ta_logo_white.svg';
import Tick from '../components/images/Tick';

const Container = styled.div`
  padding: 0;
  margin: 0;

  color: ${({ theme }) => theme.colors.textDark};

  @media ${device.desktop} {
    display: flex;
    min-height: 100vh;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
`;

const FullScreenVideoPlayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
  iframe {
    width: 100vw !important;
    height: 56.25vw !important;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    min-width: 177.77vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const TitleContainer = styled.div`
  position: relative;
  padding: 18rem 0 1.5rem 0;
  color: ${({ theme }) => theme.colors.textLight};
  margin-left: 4rem;

  @media ${device.tablet} {
    padding: 22rem 0 1.5rem 0;
  }
  @media ${device.desktop} {
    padding: 1.5rem 0 1.5rem 0;
    margin: auto 5vw 5vw 10vw;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
const TaLogo = styled.img`
  width: 100%;
  max-width: 24rem;
  margin-left: -2.75rem;

  @media ${device.desktop} {
    max-width: 48rem;
    margin-left: -5rem;
  }
`;

const LogoHolder = styled.div`
  width: 5.7rem;

  @media ${device.desktop} {
    width: 19.5rem;
  }
`;

const H1 = styled.h1`
  margin-top: 4.5rem;
  font-size: 1.82rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.textLight};

  font-weight: 700;

  div {
    position: relative;
    height: 6rem;

    strong {
      position: absolute;
      left: 0;
      top: 0;

      font-weight: 900;
      display: inline;
      opacity: 0;

      :nth-of-type(1) {
        animation: fade 12s ease infinite;
      }
      :nth-of-type(2) {
        animation: fade 12s ease 4s infinite;
      }
      :nth-of-type(3) {
        animation: fade 12s ease 8s infinite;
      }
    }
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    28% {
      opacity: 1;
    }
    33% {
      opacity: 0;
    }
  }

  @media ${device.desktop} {
    font-size: 5.625rem;

    div {
      height: 6rem;
    }
  }
`;

const H2 = styled.h2`
  font-weight: normal;
  margin-top: 1rem;
  font-size: 1.5rem;
  line-height: 2rem;

  strong {
    text-transform: uppercase;
  }

  @media ${device.desktop} {
    margin-top: 4.5rem;
    font-size: 3rem;
    line-height: 4rem;
  }
`;

const InfoPanel = styled.section`
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.textDark};
  padding: 2rem 1.5rem;

  @media ${device.desktop} {
    flex-shrink: 0;
    width: 30vw;

    padding: 1vw 3vw;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`;

const FreeAccess = styled.h2`
  display: flex;
  flex-direction: column;
  align-items: center;

  .lead {
    font-weight: bold;
    display: block;
    font-size: 3.48rem;
  }

  font-size: 1.6rem;
  font-weight: normal;
  font-weight: 300;

  strong {
    font-weight: bold;
  }

  @media ${device.desktop} {
    font-size: 2.875rem;

    .lead {
      font-size: 6.25rem;
      margin-bottom: 1.5rem;
    }
  }
`;

const TickHolder = styled.div`
  width: 2rem;

  @media ${device.desktop} {
    width: 5rem;
  }
`;

const FormContainer = styled.div`
  margin: 0 1.5rem;
`;

const Tease = styled.p`
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
  margin: 1.5rem 0;

  @media ${device.desktop} {
    font-size: 1.875rem;
    margin: 2rem 0;
  }
`;

const FormTitle = styled.h2`
  font-size: 1.17;
  font-weight: 900;
  text-align: center;
  margin: 1rem 0;

  strong {
    text-transform: uppercase;
  }

  @media ${device.desktop} {
    font-size: 2.375rem;
    margin: 3rem 0;
  }
`;

const Legal = styled.p`
  text-align: center;
  font-size: 0.625rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.backgroundDark};
  margin-top: 1rem;

  @media ${device.desktop} {
    font-size: 1.25rem;
    margin-top: 4rem;
  }
`;

const SignOff = styled.h4`
  font-family: 'Montserrat';
  font-size: 0.75rem;
  font-weight: 300;

  margin-top: 3rem;

  text-align: center;

  strong {
    font-size: 1rem;
    font-weight: 800;
    padding-top: 3px;
    text-transform: uppercase;
    display: block;
  }

  @media ${device.desktop} {
    font-size: 1rem;
    strong {
      font-size: 1.8rem;
    }
  }
`;

const LandingImageContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url(${LandingImage});
  background-size: cover;
  background-position: center center;
`;

const IndexPage = () => (
  <Layout>
    <Container>
      <GlobalStyle />
      <Registered registered={false} to="/videos" />
      <SEO title="Home" />
      <VideoContainer>
        <LandingImageContainer />
        <TitleContainer>
          {/* <FullScreenVideoPlayer>
            <ReactPlayer url="https://vimeo.com/70591644" playing muted loop />
          </FullScreenVideoPlayer> */}
          <TaLogo src={LogoTa} />
          <H2>
            With <strong>Dr Gabor Maté</strong> and{' '}
            <strong>Hameed Ali (A H Almaas)</strong>
            <br />
            Hosted by <strong>Alex Howard</strong>
          </H2>
        </TitleContainer>
      </VideoContainer>
      <InfoPanel>
        <FreeAccess>
          <TickHolder>
            <Tick />
          </TickHolder>
          <span className="lead">Free access</span>
          <div>
            to the <strong>3-part</strong> video series
          </div>
        </FreeAccess>
        <Tease>
          If you want to learn how trauma can be a portal for spiritual
          awakening, this is a MUST watch series from Dr Gabor Maté and Hameed
          Ali (A H Almaas).
        </Tease>
        <FormContainer>
          <FormTitle>
            It&rsquo;s <strong>free</strong> and <strong>easy</strong>
          </FormTitle>
          <RegisterForm />
          <Legal>
            By submitting this form, I consent to receive offers and related
            promotional communications from Conscious Life. I can withdraw
            consent at any time.
          </Legal>
        </FormContainer>
        <SignOff>
          A program by <strong>Conscious Life</strong>
        </SignOff>
      </InfoPanel>
    </Container>
  </Layout>
);

export default IndexPage;
