import axios from 'axios';

import { getUrlParameter, isBrowser } from './url';

const key = 'trauma:isRegistered';

const registerUrl =
  'https://92ashkjqha.execute-api.eu-west-2.amazonaws.com/trauma/register';

export const registerForReset = async ({ name, email }) => {
  if (!isBrowser) {
    return;
  }

  const affiliate = getUrlParameter('affid');

  await axios.post(registerUrl, { name, email, affiliate });
  localStorage.setItem(key, true);

  trackRegistration();
};

export const isRegisteredForReset = () => {
  if (!isBrowser) {
    return false;
  }

  return localStorage.getItem(key) === 'true';
};

const trackRegistration = () => {
  try {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    window.PostAffTracker.createAction('resetoptin');
    window.PostAffTracker.register();
  } catch (e) {
    console.log(e);
  }
};
