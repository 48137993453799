import { createGlobalStyle } from 'styled-components';
import device from '../utils/device';

const GlobalStyle = createGlobalStyle`

  html {
    font-family: 'Lato';
    font-size: 16px;
  }

  body {
    margin: 0;
    padding: 0;
  }

  h1, h2, h3, h4, h5 {
    margin: 0;
  }

  * {
    box-sizing: border-box;
  }

  @media ${device.desktop} {
    html {
      font-size: calc(100vw / 160);
    }
  }
`;

export default GlobalStyle;

// 16pt = 2rem
// 95px = 6rem

// 16rem == 10 vw == 256px

// rems == pt / 8
// rems == px / 16
// vw == px / 25.6
